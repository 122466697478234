<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  setup() {
  },
  methods: {
  },
};
</script>

<style scoped></style>
