import { createRouter, createWebHashHistory } from "vue-router";
import { getLocal } from '@/utils/local.js';
let routes = [];

let theme = getLocal('theme') || "3";
let device = 'mobile';
let contexts = null;
window.theme = theme;
contexts = require.context(`../views/${process.env.VUE_APP_APP_CODE}`, true, /\.vue$/);

contexts.keys().forEach((key) => {

  let fileUrl = key.replace(/\./, "");
  // 正则匹配掉".","/index"和"vue"
  let routePath = key.replace(/\.|\/index|vue/g, "") || "/";
  let routeName = routePath.replace(/\//, "").replace(/\//, "-") || "index";
  let obj = {
    path: routePath,
    name: routeName,
    component: () => import(`@/views/${process.env.VUE_APP_APP_CODE}${fileUrl}`),
  };
  // console.log(obj)
  routes.push(obj);
});

routes.push({
  path: "/:catchAll(.*)",
  redirect: '/',
  component: () => import(`@/views/${process.env.VUE_APP_APP_CODE}/index/index.vue`)
});

const router = createRouter({
  history: createWebHashHistory(),
  //history: createWebHistory(), //remove hash from URL
  routes,
  linkActiveClass: "active-link",
});
export default router;
