import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import i18n from './assets/i18n'
import http from './plugin/http.js'
import store from './store/index.js'
const app = createApp(App);
// 请求方法挂在vue实例上
app.config.globalProperties.$http = http;
app.config.globalProperties.$cfg = process.env;

app.use(router)
app.use(store)

app.mount('#app')

document.getElementById("app").style.maxWidth = "100%!important";




